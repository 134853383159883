import * as React from 'react'
import Layout from '../../../layout'
import ChooseAvatarPage from '../../../pagesComponents/groups/choose-avatar'

const ChooseAvatar = ({ id }) => {
	return (
		<Layout title="Choose an Avatar">
			<ChooseAvatarPage id={id} />
		</Layout>
	)
}

export default ChooseAvatar
