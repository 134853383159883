import React from 'react'
import BasePage from '../../components/basePage'
import { Box, Flex, Button, Image, useToast } from '@chakra-ui/react'
import { avatars } from '../../helpers'
import { useState, useContext } from 'react'
import { getTempTeam } from '../../helpers/database'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createTeam, updateTeam } from '../../services/team'
import { navigate } from 'gatsby'
import { errorToastOptions } from '../../helpers'
import CreateTeamPage from './create-team'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'

const ChooseAvatarPage = ({ id, teamId }) => {
	const tempTeam = getTempTeam() || {}
	const queryClient = useQueryClient()
	const toast = useToast()
	const { user } = useAuth()
	const [avatar, setAvatar] = useState({ id: '1', index: 0 })

	const createMutation = useMutation(createTeam, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['team'])
			eventTracking('team_created', {
				group_id: data?.groupId,
				team_id: data?.id,
				user_id: user?.uid
			})
			await navigate(`/groups/${id}/team/${data.id}/team-created/`)
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const updateMutation = useMutation(updateTeam, {
		onSuccess: async (data) => {
			await navigate(`/groups/${id}/team/${teamId}/team-created/`)
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onSubmit = () => {
		if (!tempTeam?.name) {
			if (!!teamId) {
				navigate(`/groups/${id}/team/${teamId}/update-team/`)
			} else {
				navigate(`/groups/${id}/create-team/`)
			}
			return
		}
		if (!!teamId) {
			updateMutation.mutate({
				id: teamId,
				name: tempTeam.name,
				avatar: avatar.id || '1'
			})
		} else {
			createMutation.mutate({
				groupId: parseInt(id),
				isDefault: false,
				name: tempTeam.name,
				avatar: avatar.id || '1'
			})
		}
	}

	if (!tempTeam?.name) {
		if (!!teamId) {
			return <CreateTeamPage id={id} teamId={teamId} />
		} else {
			return <CreateTeamPage id={id} />
		}
	}

	return (
		<BasePage
			backBtn={-1}
			theme="dark"
			noPadding
			largeTitle={
				<>
					Pick an
					<br />
					avatar
				</>
			}
			primaryBtn={
				<Flex w="100%" px="20px" pb="20px">
					<Button
						variant="primary"
						isLoading={
							createMutation.isLoading || updateMutation.isLoading
						}
						disabled={
							createMutation.isLoading || updateMutation.isLoading
						}
						onClick={() => onSubmit()}
					>
						Continue
					</Button>
				</Flex>
			}
		>
			<Flex justifyContent="center" alignItems="center">
				<Flex
					w="100%"
					maxH="400px"
					overflowX="scroll"
					mt="40px"
					flexWrap="wrap"
					direction="column"
					pl="13px"
				>
					{avatars.map(({ id, path }, index) => (
						<Box key={index} my="6px" px="7.5px">
							<Button
								variant="avatar"
								choosed={avatar?.index === index}
								key={index}
								id={index}
								onClick={() =>
									setAvatar({
										id,
										index
									})
								}
							>
								<Image
									src={path}
									width="90px"
									key={index}
									style={{ pointerEvents: 'none' }}
								/>
							</Button>
						</Box>
					))}
				</Flex>
			</Flex>
		</BasePage>
	)
}

export default ChooseAvatarPage
